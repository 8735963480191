import { Component, OnInit } from '@angular/core';
import { Observable, range } from 'rxjs';
import { EthioBeansServiceService } from '../service/ethio-beans-service.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
    // time = new Observable(observer =>
    //   setInterval(() => observer.next(new Date().toString()), 1000)
    // );
  constructor(private myService: EthioBeansServiceService) { }

  ngOnInit() {
    const observable = new Observable(observer => {
      for(let i=0; i<5;i++){
        observer.next(i+1);
      }
    });
    const source = range(1, 5);
    const myObserver = {
      next: x => console.log('Observer got a next value: ' + (x+1)),
      error: err => console.error('Observer got an error: ' + err),
      complete: () => console.log('Observer got a complete notification'),
    };
    observable.subscribe(myObserver);
  }

}
