import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { MenuComponent } from './menu/menu.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SpicesComponent } from './spices/spices.component';
import { CoffeeComponent } from './coffee/coffee.component';
import {EventComponent} from './event/event.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
},
{
  path: 'about-us',
  component: AboutUsComponent,
},
{
  path: 'menu',
  component: MenuComponent,
},
{
  path: 'coffee',
  component: CoffeeComponent,
},
{
  path: 'contact-us',
  component: ContactUsComponent,
},
  {
    path: 'event',
    component: EventComponent,
  },
{
  path: 'spices',
  component: SpicesComponent,
},
{
    path: '**',
    redirectTo: 'menu',
    pathMatch: 'full'
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
