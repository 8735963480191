import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home.component';
import { MenuComponent } from './menu/menu.component';
import { SpicesComponent } from './spices/spices.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { Routes, RouterModule } from '@angular/router';
import { MatMenuModule, MatToolbarModule, MatButtonModule, MatIconModule, MatCardModule, } from '@angular/material';
import { NgImageSliderModule } from 'ng-image-slider';
import { CoffeeComponent } from './coffee/coffee.component';
import { SliderModule } from 'angular-image-slider';
import { ImageViewerModule } from 'ngx-image-viewer';
import { HttpClient } from 'selenium-webdriver/http';
import { HttpClientModule } from '@angular/common/http';
import { EthioBeansHttpClientService } from './service/ethio-beans-http-client.service';
import { EventComponent } from './event/event.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    SpicesComponent,
    ContactUsComponent,
    AboutUsComponent,
    CoffeeComponent,
    EventComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    NgImageSliderModule,
    SliderModule,
    ImageViewerModule.forRoot(),
    HttpClientModule,
  ],
  providers: [EthioBeansHttpClientService],
  bootstrap: [AppComponent]
})
export class AppModule { }
