import { Injectable } from '@angular/core';
import { EthioBeansHttpClientService } from './ethio-beans-http-client.service';
import { Observable, of } from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EthioBeansServiceService {
  private baseUrl = 'https://ethio-beans.firebaseio.com/';
  slides: any;
  status: string;
  constructor(private http: EthioBeansHttpClientService) { }
  getSlidePath(url: string, complete) {
    return this.http.get(this.baseUrl + url)
      .pipe(
        catchError(() => {
          this.status = 'Error';
          return of(this.status);
        }),
        finalize(complete))
        .subscribe((response) => this.oncomplete(response));
  }
  oncomplete(response) {
    if (this.status !== 'Error') {
      this.slides = response;
    }
  }
}
