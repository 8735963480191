import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EthioBeansHttpClientService {

  constructor(private http: HttpClient) { }

  get(url: string) {
    return this.http.get(url);
  }
  post(url: string, body: any) {
    return this.http.post(url, body, this.buildHeader());
  }
  private buildHeader() {
    return    {headers: new HttpHeaders({'Content-Type': 'application/json'})};
  }
}
