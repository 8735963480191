import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-coffee',
  templateUrl: './coffee.component.html',
  styleUrls: ['./coffee.component.css']
})
export class CoffeeComponent implements OnInit {
 public coffees;
 public imageUrl;
 imageView = false;
  elementRef: ElementRef;
  constructor() { }

  ngOnInit() {
    this.imageView = false;
    this.coffees = [{
      title: 'Coffee Cultural',
      src: '../assets/images/drinks/traditional_coffee.jpg',
      content: 'Two cup of coffee with pop corn, Kolo (additional $2.00)',
      footer: '$5.00'
    },
    {
      title: 'Coffee to go',
      src: '../assets/images/drinks/coffee_to_go.jpg',
      content: 'Medium: $3.25',
      footer: 'Large :$4.25'
    }, {
      title: 'Raw Ethiopia coffee beans',
      src: '../assets/images/drinks/raw_coffee.jpg',
      content: '',
      footer: '$6.00 per lb'
    },
    {
      title: 'Freshly Roasted Ethiopia coffee beans',
      src: '../assets/images/drinks/roasted_coffee.jpg',
      content: '',
      footer: '$10.00 per lb'
    },
    {
      title: 'Freshly Grounded Ethiopia coffee',
      src: '../assets/images/drinks/ground_coffee.jpg',
      content: '',
      footer: '$15.00 per lb'
    }];
  }
  handleClick(value) {
    this.imageView = true;
    this.imageUrl = value;
  }
  restImageView() {
    this.imageView = false;
  }
}
