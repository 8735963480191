import { Component, OnInit, ViewChild, SystemJsNgModuleLoader } from '@angular/core';
import { IImage } from 'ng-simple-slideshow';
import { NgImageSliderComponent } from 'ng-image-slider';
import { async } from 'q';
import { EthioBeansServiceService } from './service/ethio-beans-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public imagesUrl;
  public index = 0;
  public url;
  public interval;
  testFolder = '../assets/images/slides/';
  fs: any;
  constructor(private ethioBeansService: EthioBeansServiceService) {

  }
  ngOnInit() {
    this.ethioBeansService.getSlidePath('slide/wk3cud9jjW6sYQ8fwiYr', this.onSlideDataReriveCompleted);
    this.imagesUrl = [
      '../assets/images/slides/slide1.gif',
      '../assets/images/slides/slide2.gif',
      '../assets/images/slides/slide20.gif',
      '../assets/images/slides/slide3.gif',
      '../assets/images/slides/slide4.gif',
      '../assets/images/slides/slide5.gif',
      '../assets/images/slides/slide6.gif',
      '../assets/images/slides/slide7.gif',
      '../assets/images/slides/slide8.gif',
      '../assets/images/slides/slide9.gif',
      '../assets/images/slides/slide10.gif',
      '../assets/images/slides/slide11.gif',
      '../assets/images/slides/slide12.gif',
      '../assets/images/slides/slide13.gif',
      '../assets/images/slides/slide18.gif',
    ];
    this.url = this.imagesUrl[0];
    this.playImages();
  }
  onSlideDataReriveCompleted = () => {
    console.log(this.ethioBeansService.slides);
  }
  next() {
    if (this.index === this.imagesUrl.length - 1) {
      this.index = 0;
    } else {
      this.index = this.index + 1;
    }
    this.url = this.imagesUrl[this.index];
  }
  playImages = () => {
    this.interval = setInterval(() => {
      const currentUrl = window.location.href;
      const splitUrl = currentUrl.split('/');
      if ('home' === splitUrl[splitUrl.length - 1] ) {
        this.next();
      } else {
        clearInterval(this.interval);
      }
    }, 3000);
  }
}
