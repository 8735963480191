import { Component, OnInit } from '@angular/core';
import { Menu } from './menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public menues;
  public imageUrl;
  imageView = false;
  constructor() { }

  ngOnInit() {
    this.menues = [
      {
        title: '1. Special Spicy Chicken curry (ልዩ የዶሮ ወጥ)',
        src: '../assets/images/menu/chickenCurryDoroWot.jpg',
        content: 'Chicken curry seasoned with blend red pepper and Injera'
        , footer: '$18.00'
      },
      {
        title: '2. Well done Tebes (ደረቅ ጥብስ)',
        src: '../assets/images/menu/wellDoneTebes.jpg',
        content: 'Cubed beaf meat, Onion, tomato, Green pepper, salt and Injera'
        , footer: '$15.00'
      },
      {
        title: '3. Juicy Tebes (መረቅ ጥብስ)',
        src: '../assets/images/menu/goatLambJuicyTebes.jpg',
        content: 'Cubed beaf meat, Onion, tomato, Green pepper, salt and Injera'
        , footer: '$14.00'
      }, {
        title: '4. Goat/lamb Shekla Tebes (የበግ/የፍየል ሸክላ ጥብስ)',
        src: '../assets/images/menu/SHEKILA_TIBS.jpg',
        content: 'Cubed goat/lamb meat, Onion, tomato, Green pepper, seasoned blend pepper poder, saltand Injera',
        footer: '$25.00'
      },
      {
        title: '5. Goat/lamb Well done Tebes ( የበግ/የፍየል ደረቅ ጥብስ)',
        src: '../assets/images/menu/wellDoneTebes.jpg',
        content: 'Cubed goat/lamb meat, Onion, tomato, Green pepper, salt and Injera'
        , footer: '$17.00'
      },
      {
        title: '6. Goat/lamb Juicy Tebes (የበግ/የፍየል መረቅ ጥብስ)',
        src: '../assets/images/menu/goatLambJuicyTebes.jpg',
        content: 'Cubed goat/lamb meat, Onion, tomato, Green pepper, salt and Injera'
        , footer: '$17.00'
      },
      {
        title: '7. Special Kitifo (ልዩ ክትፎ)',
        src: '../assets/images/menu/special_kitifo.jpeg',
        content: 'Injer, Lean fresh beef minced and lightly seasoned with a touch of seasoned butter and' +
         '\'mitmitta\' a mix of ground chili pepper and spices. With White cheese and Cabbage'
        , footer: '$18.00'
      },
      {
        title: '8. Kitifo (ክትፎ)',
        src: '../assets/images/menu/kitifo.jpeg',
        content: 'Injera, Lean fresh beef minced and lightly seasoned with a touch of seasoned butter and ' +
        '\'mitmitta\' a mix of ground chili pepper and spices.'
        , footer: '$15.00'
      },
      {
        title: '9. Gored Gored (ጎረድ ጎረድ)',
        src: '../assets/images/menu/gored_gored.jpeg',
        content: 'Injera, Cubed beef, seasoned butter, Cardamom, onion and \'mitmitta\' a mix of ground chili pepper and spices.'
        , footer: '$14.00'
      },
      {
        title: '10. Tegabino Shiro (ተጋቢኖ ሽሮ)',
        src: '../assets/images/menu/tegabinoShiro.jpg',
        content: 'Injer, Powdered chickpeas or broad bean meal, minced onions, garlic, salt,seasoned butter (Optional), Side salad'
        , footer: '$10.00'
      },
      {
        title: '11. Veggie Combo(በየአይነቱ)',
        src: '../assets/images/menu/veggieCombo.jpg',
        content: 'Injer, lentus curry , Kik Alicha, Green Kal , Cabage'
        , footer: '$15.00'
      }];
  }
  handleClick(value) {
    this.imageView = true;
    this.imageUrl = value;
  }
  restImageView() {
    this.imageView = false;
  }
}
